import React from "react";
import { Modal } from "react-bootstrap";

function ViewBody({ body, show, handleClose, title }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewBody;
