import axios from "axios";

export const ANNOUNCEMENT_URL =
  process.env.REACT_APP_ANNOUNCEMENT_ENDPOINT + "/announcement";

export function acknowledgeAnnouncement(id) {
  return axios.post(`${ANNOUNCEMENT_URL}/usermodule/acknowledge/${id}`);
}

export function fetchPreviewAnnouncements(queryParams) {
  return axios.get(
    `${ANNOUNCEMENT_URL}/usermodule/preview?query=${JSON.stringify(
      queryParams
    )}`
  );
}
