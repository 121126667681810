/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import Swal from 'sweetalert2'
import '../../../../_metronic/_assets/sass/pages/users/login-1.scss'
import * as crud from '../../Application/_redux/admin/adminCrud'
import {
	AUTHENTICATEMODULE_URL,
	AUTHENTICATEMODULE_REDIRECT_URI,
} from '../../../config'
import SVG from 'react-inlinesvg'

var UPDATEDAUTHENTICATEMODULE_URL = AUTHENTICATEMODULE_URL

export function Authenticatemodule(props) {
	const urlParams = new URLSearchParams(window.location.search)
	let code = ''
	let moduleurl = ''
	const [tokenGet, setTokenGet] = useState(false)
	const [show_login_screen, setShowLoginScreen] = useState(true)
	const [JWTtoken, setJWTtoken] = useState('')
	const [urlOfModule, setUrlOfModule] = useState('')
	const [redirectUrlOfModule, setRedirectUrlOfModule] = useState('')
	const [show_business_screen, setBusinessScreen] = useState(false)
	const [businessList, setBusinessList] = useState({ data: [] })
	const [haveBusinessList, setHaveBusinessList] = useState(false)
	//the below check will happen when we will come (authenticated) from amazon, so amazon will send us a code and a state paremeter that we have sent to them, the state param will have the moduleurl
	//

	const { token } = useSelector(
		({ auth }) => ({
			token: auth.authToken,
		}),
		shallowEqual,
	)

	const businessSelected = async (business_id) => {
		setBusinessScreen(false)
		let data = {}
		data['business_id'] = business_id
		data['token'] = JWTtoken
		let switchBusinessResponse = await crud.switch_business(data)
		if (switchBusinessResponse.status === 1) {
			let moduleBusinessIdResponse = await crud.get_module_business_id({
				token: switchBusinessResponse.data.token,
				moduleurl: urlOfModule,
			})
			if (moduleBusinessIdResponse.data.status) {
				let module_permissions_response = await crud.get_module_permission({
					module_name: moduleBusinessIdResponse.data.data,
					token: switchBusinessResponse.data.token,
				})
				module_permissions_response = module_permissions_response.data
				if (module_permissions_response.status) {
					let data = module_permissions_response.data
					// let url = data.url
					window.opener.postMessage(
						{
							token: switchBusinessResponse.data.token,
							module_id: data.module_id,
						},
						'*',
					)
					window.close()
					// window.location.href = redirectUrlOfModule+"/moduleauthenticated?token="+switchBusinessResponse.data.token+'&module_id='+data.module_id
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: "You don't have permissions to access this module",
						showCancelButton: false,
						showConfirmButton: true,
						confirmButtonText: 'OK',
					}).then(async (res) => {
						window.close()
					})
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: moduleBusinessIdResponse.data.message,
					showCancelButton: false,
					showConfirmButton: true,
					confirmButtonText: 'OK',
				}).then(() => {
					setShowLoginScreen(true)
					window.close()
				})
			}
		} else {
			let message = ''
			if (switchBusinessResponse.hasOwnProperty('errorMessage')) {
				message = switchBusinessResponse.errorMessage
			} else if (switchBusinessResponse.hasOwnProperty('message')) {
				message = switchBusinessResponse.message
			} else {
				message = 'Sorry! an unknown error occured, please try again'
			}

			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: message,
				showCancelButton: false,
				showConfirmButton: true,
				confirmButtonText: 'OK',
			}).then(() => {
				setShowLoginScreen(true)
				window.close()
			})
		}
	}
	const redirectToUserModule = async (token, moduleurl, redirecturl) => {
		if (!tokenGet) {
			setJWTtoken(token)
			setTokenGet(true)
			setUrlOfModule(moduleurl)
			setRedirectUrlOfModule(redirecturl)
			let businesListResponse = await crud.get_business_list({
				loginwithusermodule: true,
				token: token,
			})
			if (businesListResponse.status && businesListResponse.data.length > 0) {
				setShowLoginScreen(false)
				setBusinessScreen(true)
				setHaveBusinessList(true)
				setBusinessList({ data: businesListResponse.data })
			} else {
				setShowLoginScreen(false)
				setBusinessScreen(true)
				setHaveBusinessList(false)
				setBusinessList({ data: [] })
			}
		}
	}

	//we need id of the module from which user is coming from, we will concatenate it with amazon login URL, so that we can get it back with amazon response
	if (urlParams.get('moduleurl')) {
		if (token) {
			if (!tokenGet && show_login_screen) setShowLoginScreen(false)
			let moduleurl = urlParams.get('moduleurl')
			let redirecturl = urlParams.get('redirecturl')
			redirectToUserModule(token, moduleurl, redirecturl)
		} else {
			UPDATEDAUTHENTICATEMODULE_URL =
				AUTHENTICATEMODULE_URL +
				'&state=' +
				urlParams.get('moduleurl') +
				',' +
				urlParams.get('redirecturl')
		}
	}

	const authenticate_user_for_module = async (code, moduleurl, redirecturl) => {
		let data = {}
		data['code'] = code
		data['REDIRECT_URI'] = AUTHENTICATEMODULE_REDIRECT_URI
		let access_token_response = await crud.getToken(data)
		//if we do not get access_token from above API then call logout
		if (!access_token_response.hasOwnProperty('access_token')) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Login error',
				showCancelButton: false,
				showConfirmButton: true,
				confirmButtonText: 'OK',
			}).then(async (res) => {
				window.close()
			})
		}
		//let it go further
		data['access_token'] = access_token_response.access_token

		let response = await crud.create_and_save_jwt_token(data)
		if (response.status) {
			redirectToUserModule(response.token, moduleurl, redirecturl)
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: "You don't have permissions to access this module",
				showCancelButton: false,
				showConfirmButton: true,
				confirmButtonText: 'OK',
			}).then(async (res) => {
				window.close()
			})
		}
	}

	if (urlParams.get('code') && show_login_screen && !tokenGet) {
		code = urlParams.get('code')
		moduleurl = urlParams.get('state').split(',')
		authenticate_user_for_module(code, moduleurl[0], moduleurl[1])
		setShowLoginScreen(false)
	}

	return (
		<>
			<div className='d-flex flex-column flex-root'>
				<div
					className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white'
					id='kt_login'
				>
					<div
						className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
						style={{
							backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
						}}
					>
						<div className='d-flex flex-row-fluid flex-column justify-content-between '>
							{/* start:: Aside header */}
							<a
								href='https://singlelogin.io'
								className='flex-column-auto mt-5'
							>
								<img
									alt='Logo'
									className='max-h-50px'
									src='/media/logos/white-logo.png'
								/>
							</a>
							{/* end:: Aside header */}
							{/* start:: Aside content */}
							<div className='mt-2 flex-column-fluid d-flex flex-column justify-content-center'>
								<h3 className='font-size-h1 text-white mb-1'>
									Welcome to Single Sign In
								</h3>
								<span className='text-white'>Multi Tenant System</span>
							</div>

							<div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10'>
								<div className='opacity-70 font-weight-bold	text-white'>
									&copy; {new Date().getFullYear()} SingleLogin
								</div>
								<div className='d-flex'>
									<a href='https://singlelogin.io' className='text-white'>
										Privacy
									</a>
									<a href='https://singlelogin.io' className='text-white ml-10'>
										Legal
									</a>
									<a href='https://singlelogin.io' className='text-white ml-10'>
										Contact
									</a>
								</div>
							</div>
							{/* end:: Aside footer for desktop */}
						</div>
						{/*end: Aside Container*/}
					</div>

					{/*begin::Aside*/}

					{/*begin::Content*/}
					<div className='flex-row-fluid d-flex flex-column position-relative pb-0 pl-7 pt-7 pr-7 overflow-hidden'>
						{/* begin::Content body */}
						<div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
							<div className='login-form login-signin'>
								{show_login_screen ? (
									<div className='text-center mb-10 mb-lg-10'>
										<h3 className='font-size-h1'>Single Sign In</h3>
										<h6>Please login to get authenticated</h6>
										<div className='text-center'>
											<a className='mx-3' href={UPDATEDAUTHENTICATEMODULE_URL}>
												<button
													className='px-8 my-1 btn btn-primary'
													color='primary'
													type='button'
												>
													Sign In
												</button>
											</a>
										</div>
									</div>
								) : show_business_screen ? (
									<div className='text-center mb-10 mb-lg-10'>
										<h3 className='font-size-h1'>Single Sign In</h3>
										{/* <h6>Please Select Business</h6>
										{!haveBusinessList ? (
											<div className='text-center'>Please Add Busines</div>
										) : (
											<div className='text-center switch_business_div_module'>
												{businessList.data.map((business, key) => {
													return (
														<div
															key={key}
															className={
																'd-flex justify-content-between py-0 px-4 align-items-center'
															}
														>
															<span>{business.name}</span>
															<button
																onClick={() => businessSelected(business.id)}
																className='px-8 my-1 btn btn-primary'
																color='primary'
																type='button'
															>
																Select
															</button>
														</div>
													)
												})}
											</div>
										)} */}
										{/*begin::List Widget 1*/}
										<div className='card card-custom card-stretch gutter-b mt-5'>
											{/*begin::Header*/}
											<div className='card-header border-0 pt-5'>
												<h3 className='card-title align-items-start flex-column'>
													<span className='card-label font-weight-bolder text-dark'>
														Please Select a Business
													</span>
													{/* <span className='text-muted mt-3 font-weight-bold font-size-sm'>
														Pending 10 tasks
													</span> */}
												</h3>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className='card-body pt-1'>
												{businessList.data.map((business, key) => (
													<div
														onClick={() => businessSelected(business.id)}
														key={key}
														className='d-flex align-items-center mb-3 bg-primary-o-20 bg-hover-state-primary rounded-sm p-3 text-dark text-hover-light'
													>
														{/*begin::Symbol*/}
														<div className='symbol symbol-40 symbol-light-primary mr-5'>
															<span className='symbol-label btn btn-icon btn-light'>
																<span className='svg-icon svg-icon-md svg-icon-primary'>
																	<SVG
																		src={toAbsoluteUrl(
																			'/media/svg/icons/Home/Building.svg',
																		)}
																	/>
																</span>
															</span>
														</div>
														{/*end::Symbol*/}
														{/*begin::Text*/}
														<div className='d-flex flex-column font-weight-bold'>
															<span
																// href='#'
																className='font-size-lg text-capitalize'
															>
																{business.name}
															</span>
															{/* <span className='text-muted'>Project Manager</span> */}
														</div>
														{/*end::Text*/}
													</div>
												))}
											</div>
											{/*end::Body*/}
										</div>
										{/*end::List Widget 1*/}
									</div>
								) : (
									<h3 className='font-size-h1'>Please wait ...</h3>
								)}
							</div>
						</div>
						{/*end::Content body*/}

						{/* begin::Mobile footer , right now hidden*/}
						<div className='d-none d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5'>
							<div className='text-dark-50 font-weight-bold order-2 order-sm-1 my-2'>
								&copy; {new Date().getFullYear()} SingleLogin
							</div>
							<div className='d-flex order-1 order-sm-2 my-2'>
								<a
									href='https://singlelogin.io'
									className='text-dark-75 text-hover-primary'
								>
									Privacy
								</a>
								<a
									href='https://singlelogin.io'
									className='text-dark-75 text-hover-primary ml-4'
								>
									Legal
								</a>
								<a
									href='https://singlelogin.io'
									className='text-dark-75 text-hover-primary ml-4'
								>
									Contact
								</a>
							</div>
						</div>
						{/* end::Mobile footer */}
					</div>
					{/*end::Content*/}
				</div>
				{/*end::Login*/}
			</div>
		</>
	)
}
