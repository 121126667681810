import React from "react";
import { Spinner } from "react-bootstrap";

function AcknowledgeButton({ id, handleAcknowledge, loading, loadingId }) {
  return (
    <button
      type="button"
      className="btn btn-danger font-weight-bold py-2 px-6 ml-2"
      onClick={() => handleAcknowledge(id)}
    >
      Acknowledge
      {loading && loadingId === id && (
        // <span className="spinner-border text-light spinner-sm"></span>
        <>
          {" "}
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </>
      )}
    </button>
  );
}

export default AcknowledgeButton;
