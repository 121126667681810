import {
  initialPreviewFilter,
  initialFilter
} from "../../pages/Announcements/utils/announcementUiHelpers";
import * as requestFromServer from "./announcementCrud";
import { announcementSlice, callTypes } from "./announcementSlice";

const { actions } = announcementSlice;

export const acknowledgeAnnouncement = (id, query) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.acknowledgeAnnouncement(id);
    if (response.data.status) {
      let queryParams = initialPreviewFilter;
      let r = true;
      dispatch(fetchPreviewAnnouncements(query, r));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't acknowledge announcement";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const fetchPreviewAnnouncements = (
  queryParams,
  r = false
) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchPreviewAnnouncements(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data.data;
      dispatch(
        actions.announcementsPreviewFetched({
          totalCount,
          entities,
          r
        })
      );
    })
    .catch(error => {
      console.log(error);
      error.clientMessage = "Can't find announcements";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
