import * as config from "../app/config";
import Swal from "sweetalert2";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    async response => {
      let our_request;
      try {
        our_request = JSON.parse(response.config.data);
      } catch (error) {
        our_request = JSON.parse(JSON.stringify({}));
      }
      if (!response.data.errorMessage) {
        return response;
      } else if (
        response.data.hasOwnProperty("errorMessage") &&
        response.data.errorMessage &&
        (response.data.status === "business_ip_hours_error" ||
          response.data.status === "business_profile_ip_hours_error")
      ) {
        document.body.style.pointerEvents = "";
        if (document.getElementById("loading_module_div")) {
          document
            .getElementById("loading_module_div")
            .classList.remove("d-flex");
          document.getElementById("loading_module_div").classList.add("d-none");
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errorMessage,
          showConfirmButton: true,
          confirmButtonText: "OK"
        }).then(() => {
          window.location.href = "/application/restricted_access";
        });

        return Promise.reject("error");
      } else {
        if (response.data.errorMessage === "TokenExpiredError: jwt expired") {
          if (our_request.hasOwnProperty("loginwithusermodule")) {
            //clear local storage
            await localStorage.removeItem("persist:" + config.LOCALSTORAGEKEY);
            window.location.reload();
          } else {
            window.location.href = "/auth/login";
          }
        }
      }
    },
    async function(error) {
      return Promise.reject(error);
    }
  );
}
