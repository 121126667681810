import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Modal } from "react-bootstrap";
import * as adminCrud from "../modules/Application/_redux/admin/adminCrud";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import AnnouncementCard from "../modules/Application/pages/Announcements/AnnouncementCard";

import { toAbsoluteUrl } from "../../_metronic/_helpers";
import Chart from "react-apexcharts";

export function DashboardPage(params) {
  const { authToken } = useSelector(state => state.auth);
  const admin = useSelector(state => state.admin);

  let useparams = useParams();
  let ask_for_referral_code = parseInt(useparams.ask_for_referral_code);

  const [referral_code_modal, showReferralCodeModal] = useState(false);
  const [processing, setProcessing] = useState("");
  const [usersLabels, setUserLabels] = useState({ data: [] });
  const [usersCount, setUserCount] = useState(0);
  const [departmentLabels, setDepartmentLabels] = useState({ data: [] });
  const [departmentCount, setDepartmentCount] = useState(0);
  const [profilesLabels, setProfilesLabels] = useState({ data: [] });
  const [profilesCount, setProfilesCount] = useState(0);
  const [rolesLabels, setRolesLabels] = useState({ data: [] });
  const [rolesCount, setRolesCount] = useState({ data: [] });

  const [referral_code, setReferralCode] = useState("");

  const [inviteLinksCount, setInviteLinksCount] = useState(0);
  const [businessrolesCount, setBusinessRolesCount] = useState(0);
  const [subscriptionsCount, setSubscriptionsCount] = useState(0);
  const [modulesCount, setModulesCount] = useState(0);

  const [validate_referral_code, validateRederralCode] = useState("");

  const save_referral_code = async () => {
    let validations_ok = true;
    validateRederralCode("");

    if (!referral_code) {
      validations_ok = false;
      validateRederralCode("is-invalid");
    }

    if (validations_ok) {
      //save this referral code with this user
      setProcessing("save_referral_code");

      let response = await adminCrud.save_referral_code({
        token: authToken,
        code: referral_code.trim()
      });
      response = response.data;
      setProcessing("");
      if (response.status) {
        showReferralCodeModal(false);
      } else {
        let message = "Error";
        if (response.hasOwnProperty("message")) {
          message = response.message;
        }

        Swal.fire({
          icon: "error",
          title: "Error!",
          text: message
        });
      }
    }
  };

  useEffect(() => {
    if (ask_for_referral_code) {
      showReferralCodeModal(true);
    }
  }, []);

  const getDashboardStats = async () => {
    if (authToken) {
      let stats = await adminCrud.get_dashboard_stats({ token: authToken });
      if (stats.data.status) {
        setInviteLinksCount(stats.data.invitelinks_count);
        setBusinessRolesCount(stats.data.roles_count);
        setSubscriptionsCount(stats.data.subscriptions_count);
        setModulesCount(stats.data.modules_count);

        let userLabels = [];
        let userCounts = [];
        for (const [key, val] of Object.entries(stats.data.users_per_date)) {
          userLabels.push(key);
          // userLabels.push(val.date)
          userCounts.push(val.count);
        }
        setUserCount({ data: userCounts });
        setUserLabels({ data: userLabels });

        let departmentLabels = [];
        let departmentCounts = [];
        for (const [key, val] of Object.entries(stats.data.departments)) {
          departmentLabels.push(val.name);
          departmentCounts.push(val.count);
        }
        setDepartmentCount({ data: departmentCounts });
        setDepartmentLabels({ data: departmentLabels });

        let profilesLabels = [];
        let profilesCounts = [];
        for (const [key, val] of Object.entries(stats.data.profiles)) {
          profilesLabels.push(val.name);
          profilesCounts.push(val.count);
        }
        setProfilesCount({ data: profilesCounts });
        setProfilesLabels({ data: profilesLabels });

        //roles
        let rolesLabels = [];
        let rolesCounts = [];
        for (const [key, val] of Object.entries(stats.data.roles)) {
          rolesLabels.push(val.name);
          rolesCounts.push(val.count);
        }
        setRolesCount({ statename: "rolescount", data: rolesCounts });
        setRolesLabels({ statename: "roleslabels", data: rolesLabels });
      }
    }
  };
  useEffect(() => {
    if (admin.business_details) {
      getDashboardStats();
    }
  }, [admin]);

  return (
    <>
      <div className="row">
        <div className="col-xl-3">
          {/* <!--begin::Stats Widget 29--> */}
          <div
            className="card card-custom bgi-no-repeat card-stretch gutter-b"
            style={{
              backgroundPosition: "right top",
              backgroundSize: "30% auto",
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/shapes/abstract-1.svg"
              )})`
            }}
          >
            {/* <!--begin::Body--> */}
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-info">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg--> */}
                <i className="fas fa-link icon-xl"></i>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {inviteLinksCount}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Invite links
              </span>
            </div>
            {/* <!--end::Body--> */}
          </div>
          {/* <!--end::Stats Widget 29--> */}
        </div>
        <div className="col-xl-3">
          {/* <!--begin::Stats Widget 29--> */}
          <div
            className="card card-custom bgi-no-repeat card-stretch gutter-b"
            style={{
              backgroundPosition: "right top",
              backgroundSize: "30% auto",
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/shapes/abstract-1.svg"
              )})`
            }}
          >
            {/* <!--begin::Body--> */}
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-info">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg--> */}
                <i className="fas fa-window-maximize icon-xl"></i>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {modulesCount}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Modules
              </span>
            </div>
            {/* <!--end::Body--> */}
          </div>
          {/* <!--end::Stats Widget 29--> */}
        </div>
        <div className="col-xl-3">
          {/* <!--begin::Stats Widget 29--> */}
          <div
            className="card card-custom bgi-no-repeat card-stretch gutter-b"
            style={{
              backgroundPosition: "right top",
              backgroundSize: "30% auto",
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/shapes/abstract-1.svg"
              )})`
            }}
          >
            {/* <!--begin::Body--> */}
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-info">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg--> */}
                <i className="fas fa-wallet icon-xl"></i>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {subscriptionsCount}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Subscriptions
              </span>
            </div>
            {/* <!--end::Body--> */}
          </div>
          {/* <!--end::Stats Widget 29--> */}
        </div>
        <div className="col-xl-3">
          {/* <!--begin::Stats Widget 29--> */}
          <div
            className="card card-custom bgi-no-repeat card-stretch gutter-b"
            style={{
              backgroundPosition: "right top",
              backgroundSize: "30% auto",
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/svg/shapes/abstract-1.svg"
              )})`
            }}
          >
            {/* <!--begin::Body--> */}
            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-info">
                {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-opened.svg--> */}
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} /> */}
                <i className="fas fa-sitemap icon-xl"></i>
                {/* <!--end::Svg Icon--> */}
              </span>
              <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {businessrolesCount}
              </span>
              <span className="font-weight-bold text-muted font-size-sm">
                Roles
              </span>
            </div>
            {/* <!--end::Body--> */}
          </div>
          {/* <!--end::Stats Widget 29--> */}
        </div>
      </div>

      <AnnouncementCard />
      {/* <div className="row">
            <div className="col mx-1 card card-custom bgi-no-repeat card-stretch gutter-b" style={{backgroundPosition: "right top", backgroundSize: '30% auto', backgroundImage: toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')}}>
                <div className="card-body">
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">{profilesCount.data}</span>
                    <span className="font-weight-bold text-muted font-size-sm">Profiles</span>
                </div>
            </div>
            <div className="col mx-1 card card-custom bgi-no-repeat card-stretch gutter-b" style={{backgroundPosition: "right top", backgroundSize: '30% auto', backgroundImage: toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')}}>
                <div className="card-body">
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">{usersCount.data}</span>
                    <span className="font-weight-bold text-muted font-size-sm">Active Users</span>
                </div>
            </div>
            <div className="col mx-1 card card-custom bgi-no-repeat card-stretch gutter-b" style={{backgroundPosition: "right top", backgroundSize: '30% auto', backgroundImage: toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')}}>
                <div className="card-body">
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">{usersCount.data}</span>
                    <span className="font-weight-bold text-muted font-size-sm">Total Users</span>
                </div>
            </div>
            <div className="col mx-1 card card-custom bgi-no-repeat card-stretch gutter-b" style={{backgroundPosition: "right top", backgroundSize: '30% auto', backgroundImage: toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')}}>
                <div className="card-body">
                    <span className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">{departmentCount.data}</span>
                    <span className="font-weight-bold text-muted font-size-sm">Deparments</span>
                </div>
            </div>
        </div> */}
      {/* <div className="row" style={{background:'white'}}>
             <div className="col-md-6">
            <Chart
              options={{
                chart: {
                  type: 'area',
                  height: '100%',
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                
                title: {
                  text: 'Monthly Users',
                  align: 'left'
                },
                subtitle: {
                  text: 'Number of users',
                  align: 'left'
                },
                xaxis: {
                  categories: usersLabels.data,
                },
                yaxis: {
                  opposite: true
                },
                legend: {
                  horizontalAlign: 'left'
                }
              }}
              series={[{
                name: "Users",
                data: usersCount.data
              }]}
              type="area"
            />
             </div>
              
            <div className="col-md-6">
            <Chart
              options={{
                chart: {
                  type: 'bar',
                  height: 350
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                  },
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: departmentLabels.data,
                },
                yaxis: {
                  title: {
                    text: 'Users By Deparments'
                  }
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return val+" users"
                    }
                  }
                }
              }
            }
              series={[{
                name: "Users",
                data: departmentCount.data
              }]}
              type="bar"
            />
            </div>
            <div className="col-md-6">
            <Chart
              options={{
                chart: {
                  width: 380,
                  type: 'pie',
                },
                labels: profilesLabels.data,
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }]
              }
            }
              series={profilesCount.data}
              type="pie"
            />
            </div>

            <div className="col-md-6">
            <Chart
              options={{
                chart: {
                  height: 350,
                  type: 'radar',
                },
                title: {
                  text: 'Users by role'
                },
                xaxis: {
                  categories: rolesLabels.data
                }
              }
            }
              series={ [{
                name: 'Series 1',
                data: rolesCount.data,
              }]}
              type="radar"
            />
            </div>


        </div> */}

      <Modal
        keyboard={false}
        backdrop={false}
        centered
        size="lg"
        show={referral_code_modal}
        onHide={() => showReferralCodeModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Referral Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12 my-10">
              <input
                onChange={e => setReferralCode(e.target.value)}
                placeholder="Please provide your referral code if you are rederred by someone"
                className={`form-control ${validate_referral_code}`}
              ></input>
              <div className="invalid-feedback">Invalid referral code</div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            onClick={() => showReferralCodeModal(false)}
            className="btn btn-light-primary font-weight-bold"
          >
            Close
          </button>
          <button
            type="button"
            onClick={() => save_referral_code()}
            className="btn btn-primary font-weight-bold"
          >
            {processing === "save_referral_code" ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              "Save"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
